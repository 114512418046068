import $ from "jquery";
import "../vendor/jquery.kladr.js";

export default class Kladr {
  constructor(config, setValue) {
    this.$region = $(`#${config.region}`);
    this.$city = $(`#${config.city}`);
    this.$address = $(`#${config.address}`);
    this.$zip = $(`#${config.zip}`);
    this.config = config;
    this.setValue = setValue;
  }
  regionInit() {
    this.$region.kladr({
      type: $.kladr.type.region,
      open: () => {
        this.$region.addClass("is-open");
      },
      closeBefore: () => {
        this.$region.removeClass("is-open");
      },
      change: res => {
        if (res) {
          this.$region.parentId = res.id;
        }
        this.$region.trigger("change");
      }
    });
  }
  cityInit(region = "5000000000000") {
    this.$city.kladr({
      type: $.kladr.type.city,
      parentType: $.kladr.type.region,
      parentId: region,
      withParents: true,
      sendBefore: req => {
        if (this.$region.parentId) {
          req.parentId = this.$region.parentId;
        }
      },
      open: () => {
        this.$city.addClass("is-open");
      },
      closeBefore: () => {
        this.$city.removeClass("is-open");
      },
      change: res => {
        if (res) {
          this.$city.parentId = res.id;
        }
      }
    });
  }
  addressInit(city = "7700000000000") {
    this.$address.kladr({
      type: $.kladr.type.street,
      parentType: $.kladr.type.city,
      parentId: city,
      sendBefore: req => {
        if (this.$city.parentId) {
          req.parentId = this.$city.parentId;
        }
      },
      open: () => {
        this.$address.addClass("is-open");
      },
      closeBefore: () => {
        this.$address.removeClass("is-open");
      },
      change: res => {
        if (res) {
          if (res.zip) {
            this.setValue({ value: res.zip, id: this.$zip.attr("id") });
          }

          this.$address.val(res.typeShort + " " + this.$address.val() + ", ");
          this.$address.trigger("change");
        }
      }
    });
  }

  init() {
    this.regionInit();
    this.cityInit();
    this.addressInit();

    for (const selector in this.config) {
      const element = $("#" + this.config[selector]);

      if (element) {
        element.focusout(() => {
          this.setValue({ value: element.val(), id: element.attr("id") });
        });
      }
    }
  }
}
